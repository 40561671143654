import { Cookie } from '@amarkets/atlas/esm/lib/cookie';
import { StorageService } from '@amarkets/atlas/esm/lib/storage';

export default class TokenSyncService {
  static _EXPIRATION_DAYS = 365;
  static _ACCESS_TOKEN_KEY = 'spa_access_token';
  static _REFRESH_TOKEN_KEY = 'spa_refresh_token';
  static _ID_TOKEN_KEY = 'spa_id_token'

  _env;
  _tokens;
  _localStorage;

  constructor(env) {
    this._env = env;
    this._localStorage = new StorageService(localStorage);
    this._tokens = this.getFromCookies();
  }

  get tokens() {
    return this._tokens;
  }

  sync(accessToken, refreshToken) {
    this._updateTokens(accessToken, refreshToken);

    Cookie.set(TokenSyncService._ACCESS_TOKEN_KEY, accessToken, {
      expires: TokenSyncService._EXPIRATION_DAYS,
    });
    Cookie.set(TokenSyncService._REFRESH_TOKEN_KEY, refreshToken, {
      expires: TokenSyncService._EXPIRATION_DAYS,
    });

    this._localStorage.set('accessToken', accessToken);
    this._localStorage.set('refreshToken', refreshToken);
  }

  remove() {
    if (this._isProduction) {
      // redirect to sing_out to remove MPA tokens(logout from main admin app)
      window.location.href = '/users/sign_out';
    }

    if (this._isDevelopment) {
      this._updateTokens();
      Cookie.remove(TokenSyncService._ACCESS_TOKEN_KEY);
      Cookie.remove(TokenSyncService._REFRESH_TOKEN_KEY);

      this._localStorage.remove('accessToken');
      this._localStorage.remove('refreshToken');
    }
  }

  get _isDevelopment() {
    return this._env === 'development';
  }

  get _isProduction() {
    return this._env === 'production';
  }

  _updateTokens(accessToken, refreshToken) {
    this._tokens = {
      accessToken,
      refreshToken,
    };
  }

  getFromCookies() {
    const accessToken = Cookie.get(TokenSyncService._ACCESS_TOKEN_KEY);

    if (accessToken) {
      return {
        accessToken,
        refreshToken: Cookie.get(TokenSyncService._REFRESH_TOKEN_KEY),
        idToken: Cookie.get(TokenSyncService._ID_TOKEN_KEY),
      };
    }

    return {
      accessToken: this._localStorage.get('accessToken'),
      refreshToken: this._localStorage.get('refreshToken'),
      idToken: this._localStorage.get('idToken'),
    };
  }
}
