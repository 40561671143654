import { parseIdToken } from '@amarkets/atlas/esm/lib/parse-id-token';
import { parseAccessToken } from '@amarkets/atlas/esm/lib/parse-access-token';
import { AuthUser } from '@/models';

export default class AuthorizationService {
  _authApiService;
  _tokenSyncService;

  constructor(authApiService, tokenSyncService) {
    this._authApiService = authApiService;
    this._tokenSyncService = tokenSyncService;
  }

  async authorize(accessToken) {
    try {
      const { userRoles, userId } = AuthorizationService._parseAccessToken(accessToken);
      const { idToken } = this._tokenSyncService.tokens;

      return new AuthUser({
        id: userId,
        roles: userRoles,
        name: AuthorizationService._parseIdToken(idToken),
      });
    } catch (e) {
      console.error(e, 'while trying to authorize');
      throw e;
    }
  }

  async logout(refreshToken, mapper) {
    try {
      await this._authApiService.logout(refreshToken, mapper);
      this._tokenSyncService.remove();
    } catch (e) {
      console.error(e, 'while trying to logout');
      throw e;
    }
  }

  static _parseAccessToken(accessToken) {
    const {
      userRoles,
      userId,
    } = parseAccessToken(accessToken);

    return {
      userRoles,
      userId,
    };
  }

  static _parseIdToken(idToken) {
    const { email } = parseIdToken(idToken);

    return email;
  }
}
